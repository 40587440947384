<template>
  <div class="modal fade" tabindex="-10" id="kt_modal_edit_ranking">
    <div class="modal-dialog">
      <loading
        :active="isLoading"
        :is-full-page="fullPage"
        :z-index="1100"
      ></loading>
      <Form
        class="form"
        @submit="onSubmit"
        :validation-schema="schema"
        @invalid-submit="onInvalidSubmit"
        v-slot="{ values }"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              Modifica classifica - {{ jsonDetailRanking.nominativo_completo }}
            </h5>
            <!--begin::Close-->
            <div
              class="btn btn-icon btn-sm btn-active-secondary"
              style="border: 0; !important"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="bi bi-x-lg"> </i>
            </div>
            <!--end::Close-->
          </div>

          <div class="modal-body">
            <div v-if="!caricato" class="mx-auto my-auto text-center py-5 my-5">
              <div class="spinner-border text-gray-600" role="status"></div>
              <span class="text-gray-600 ps-3 fs-2">Loading</span>
            </div>
            <div class="card-body p-6" v-if="caricato">
              <div class="alert alert-danger" role="alert" v-if="alertCampiObb">
                Compila i campi obbligatori
              </div>
              <div class="row pb-4">
                <div class="col-sm-4 filter">
                  <label class="fw-bold text-gray-600">Stagione</label>
                  <SelectInput
                    :options="stagioni"
                    name="stagione"
                    :value="selectedStagione"
                    @changeSelect="setRankingStagione($event)"
                  />
                </div>
                <div class="col-sm-4 filter">
                  <label class="fw-bold text-gray-600">Data decorrenza</label>
                  <Datepicker
                    v-model="data_validita"
                    name="data_validita"
                    :format="format"
                    :monthChangeOnScroll="false"
                    :enableTimePicker="false"
                    inputClassName="h-28"
                    autoApply
                  />
                  <Field
                    name="data_validita"
                    type="hidden"
                    class="form-control"
                    v-model="data_validita"
                  />
                  <ErrorMessage
                    class="help-message"
                    style="color: red"
                    name="data_validita"
                  />
                </div>
              </div>
              <div class="row pb-4">
                <div class="col-sm-4 filter">
                  <label class="fw-bold text-gray-600">Disciplina</label>
                  <SelectInput
                    :options="discipline"
                    name="id_disciplina"
                    placeholder="Disciplina"
                    :value="selectedDisciplina"
                    @changeSelect="setRankingDisciplina($event)"
                  />
                </div>
                <div
                  class="col-sm-4 filter"
                  v-if="tesseramento_ranking_categoria_classifica"
                >
                  <label class="fw-bold text-gray-600">Categoria</label>

                  <Field
                    as="select"
                    class="form-select"
                    name="id_categoria"
                    aria-label=""
                    placeholder="Categoria"
                    :value="selectedCategoria ? selectedCategoria : 0"
                    @change="setRankingCategoria($event.target.value)"
                  >
                    <template
                      v-if="
                        selectedDisciplina == 172 && selectedStagione >= 2023
                      "
                    >
                      <option :value="0" selected>Categoria</option>
                      <option
                        v-for="rank in tesseramento_ranking_categoria_classifica.slice(
                          4,
                          tesseramento_ranking_categoria_classifica.length
                        )"
                        :key="rank.id"
                        :value="rank.id"
                      >
                        {{ rank.label }}
                      </option>
                    </template>
                    <template v-else>
                      <option :value="0" selected>Categoria</option>
                      <option
                        v-for="rank in tesseramento_ranking_categoria_classifica.slice(
                          0,
                          4
                        )"
                        :key="rank.id"
                        :value="rank.id"
                      >
                        {{ rank.label }}
                      </option>
                    </template>
                  </Field>
                  <!-- <SelectInput
                    :options="tesseramento_ranking_categoria_classifica"
                    name="id_categoria"
                    placeholder="Categoria"
                    :value="selectedCategoria"
                    @changeSelect="setRankingCategoria($event)"
                  /> -->
                </div>
                <div
                  class="col-sm-4 filter"
                  v-if="
                    (selectedDisciplina == 172 && selectedStagione < 2023) ||
                    selectedDisciplina != 172
                  "
                >
                  <label class="fw-bold text-gray-600">Gruppo</label>
                  <SelectInput
                    :options="rankingGruppi"
                    name="id_gruppo"
                    placeholder="Gruppo"
                    :value="selectedGruppo"
                    @changeSelect="setRankingGruppo($event)"
                  />
                </div>
                <!-- v-if="coefficiente" -->
                <!-- v-if="selectedDisciplina == 172 && selectedStagione >= 2023" -->
                <!-- {{ coefficiente }} -->
                <div class="col-sm-4 filter" v-else>
                  <label class="fw-bold text-gray-600">Coefficiente </label>
                  <Field
                    type="text"
                    name="coefficiente"
                    class="form-control"
                    maxlength="10"
                    @input="
                      setCoefficiente($event.target.value);
                      values.coefficiente = coefficiente;
                    "
                    :value="coefficiente"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="me-3 badge bg-light-danger rounded text-danger fs-6"
              data-bs-dismiss="modal"
              aria-label="Close"
              id="closeModalEdit"
            >
              Chiudi
            </button>
            <button
              type="submit"
              class="badge bg-secondary rounded blueFit fs-6"
              :disabled="disableButton"
            >
              Salva
            </button>
          </div>
        </div>
      </Form>
    </div>
  </div>
</template>

<script>
import { defineComponent, getCurrentInstance, computed, ref, watch } from "vue";
import { Form, ErrorMessage, Field } from "vee-validate";
import * as yup from "yup";
import { useStore } from "vuex";

import Datepicker from "vue3-date-time-picker";

import SelectInput from "@/components/components-fit/utility/inputs/SelectInput.vue";

import { editRanking, detailRanking } from "../../../requests/ranking";

import Swal from "sweetalert2/dist/sweetalert2.min.js";
import "vue3-date-time-picker/dist/main.css";

import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";

export default defineComponent({
  name: "EditRanking",
  components: { Loading, Form, SelectInput, ErrorMessage, Field, Datepicker },
  props: {
    isLoaded: { type: Boolean, required: true },
    id_ranking: {
      type: Number,
      required: true,
    },
  },
  emits: ["refreshRankingList"],
  setup(props, { emit }) {
    const store = useStore();
    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;

    const discipline = computed(() =>
      store.getters.getStateFromName("discipline")
    );
    const tesseramento_ranking_categoria_classifica = computed(() =>
      store.getters.getStateFromName(
        "tesseramento_ranking_categoria_classifica"
      )
    );
    // sul getstate passa il nome array del json
    const rankingGruppi = computed(() =>
      store.getters.getStateFromName("tesseramento_ranking_gruppi")
    );

    const keys = ref("d|trcc");
    if (!discipline.value || !tesseramento_ranking_categoria_classifica.value) {
      store.dispatch("setStoreData", {
        keys: { keys: keys.value },
        apiLink: globalApi.COMBOLIST_GET,
      });
    }

    const alertCampiObb = ref(false);

    const defaultStagione = computed(() => store.getters.defaultStagione);

    const selectedStagione = ref(null);
    const selectedDisciplina = ref(null);
    const selectedCategoria = ref(null);
    const selectedGruppo = ref(null);
    const coefficiente = ref(0.0);
    selectedStagione.value = defaultStagione.value;

    const resetRankingGruppi = () => {
      store.commit("spliceArrayFromName", "tesseramento_ranking_gruppi");
    };

    const setRankingStagione = (idStagione) => {
      selectedStagione.value = parseInt(idStagione);
    };

    const setRankingDisciplina = (idDisciplina) => {
      selectedDisciplina.value = parseInt(idDisciplina);
      selectedCategoria.value = 0;
      selectedGruppo.value = null;
      resetRankingGruppi();
    };

    const setRankingCategoria = (idCategoria) => {
      selectedCategoria.value = parseInt(idCategoria);
      // pulisce i gruppi
      resetRankingGruppi();
      // i nuovi gruppi
      if (idCategoria != "Categoria") {
        store.dispatch("setStoreData", {
          keys: {
            id_disciplina: selectedDisciplina.value,
            id_categoria_classifica: selectedCategoria.value,
          },
          apiLink: globalApi.LOOKUP_TESSERAMENTO_RANKING_GRUPPI_GET,
        });
      }
    };

    const setRankingGruppo = (idGruppo) => {
      selectedGruppo.value = parseInt(idGruppo);
    };

    const nome_persona = ref("");
    const id_persona = ref(null);

    const getTesserati = (string) => {
      if (string.target.value === "") {
        store.dispatch("svuotaPersoneNomeCognome");
        return;
      }
      store.dispatch("getPersoneNomeCognome", string.target.value);
    };
    const resetTesseratiList = () => {
      store.dispatch("svuotaPersoneNomeCognome");
    };

    const spliceTesserati = () => {
      nome_persona.value = "";
      id_persona.value = 0;
      resetTesseratiList();
    };

    const schema = yup.object().shape({
      stagione: yup
        .number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .required("Obbligatorio")
        .nullable(),
      id_disciplina: yup
        .number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .required("Obbligatorio")
        .nullable(),
      id_categoria: yup
        .number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .required("Obbligatorio")
        .nullable(),
      id_gruppo: yup.number().when(["stagione", "id_disciplina"], {
        is: (stagione, id_disciplina) => {
          return (
            id_disciplina != 172 || (id_disciplina == 172 && stagione < 2023)
          );
        },
        then: yup.number().required("Obbligatorio").nullable(),
        otherwise: yup.number(),
      }),
      coefficiente: yup
        .number()
        .when(["stagione", "id_disciplina"], {
          is: (stagione, id_disciplina) => {
            return id_disciplina == 172 && stagione >= 2023;
          },
          then: yup.number().required("Obbligatorio"),
          otherwise: yup.number(),
        })
        .nullable(),
      data_validita: yup.string().required("Obbligatorio").nullable(),
    });

    const disableButton = ref(false);
    const isLoading = ref(false);

    const onSubmit = (values) => {
      values.coefficiente = parseFloat(values.coefficiente);
      disableButton.value = true;
      isLoading.value = true;
      alertCampiObb.value = false;
      const responseAdd = ref({});
      let messaggioResponse;
      values.id = props.id_ranking;
      responseAdd.value = editRanking(values);
      messaggioResponse = "Ranking modificato";
      responseAdd.value.then((value) => {
        if (value.status == 200) {
          Swal.fire({
            html: messaggioResponse,
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "OK",
            customClass: {
              confirmButton: "btn fw-bold btn-light-primary",
            },
          });
          document.getElementById("closeModalEdit").click();
          emit("refreshRankingList");
        } else {
          const strMsg = ref(
            value.data.message
              ? value.data.message
              : "Attenzione! Si è verificato un errore. Riprovare più tardi"
          );
          Swal.fire({
            html: strMsg.value,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "OK",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        }
        disableButton.value = false;
        isLoading.value = false;
      });
    };

    const onInvalidSubmit = (v) => {
      alertCampiObb.value = true;
    };

    const resetField = () => {
      alertCampiObb.value = false;
      spliceTesserati();
      selectedStagione.value = defaultStagione.value;
      selectedDisciplina.value = null;
      selectedCategoria.value = null;
      selectedGruppo.value = null;
      coefficiente.value = null;
      resetRankingGruppi();
    };

    const data_validita = ref(null);
    //date format
    const format = (date) => {
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      return `${day}/${month}/${year}`;
    };

    const jsonDetailRanking = ref({});

    const loadRanking = (id) => {
      detailRanking(id).then((res) => {
        jsonDetailRanking.value = res.results[0];
        selectedStagione.value = parseInt(jsonDetailRanking.value.stagione);
        selectedDisciplina.value = parseInt(jsonDetailRanking.value.disciplina);
        selectedCategoria.value = parseInt(jsonDetailRanking.value.categoria);
        selectedGruppo.value = parseInt(jsonDetailRanking.value.id_gruppo);
        coefficiente.value = jsonDetailRanking.value.gruppo;
        data_validita.value = jsonDetailRanking.value.data_inserimento;
        store.dispatch("setStoreData", {
          keys: {
            id_disciplina: selectedDisciplina.value,
            id_categoria_classifica: selectedCategoria.value,
          },
          apiLink: globalApi.LOOKUP_TESSERAMENTO_RANKING_GRUPPI_GET,
        });
        caricato.value = true;
      });
    };

    const caricato = ref(false);

    watch(() => {
      caricato.value = props.isLoaded;
      if (props.id_ranking != null) {
        loadRanking(props.id_ranking);
      }
    });

    const setCoefficiente = (value) => {
      const indexOfPeriod = value.indexOf(".");
      const elementsAfterPeriod =
        indexOfPeriod != -1
          ? value.substring(indexOfPeriod + 1, value.length)
          : "";
      const elementsBeforePeriod = value.substring(0, indexOfPeriod);
      if (elementsAfterPeriod.length >= 3) {
        const valueToParse =
          elementsBeforePeriod + "." + elementsAfterPeriod.substring(0, 2);
        coefficiente.value = valueToParse;
      } else {
        if (!value.includes(".")) coefficiente.value = value;
        else {
          coefficiente.value =
            elementsAfterPeriod.length > 2 ? value.toFixed(2) : value;
        }
      }
    };

    return {
      setCoefficiente,
      stagioni: computed(() => store.getters.stagioni),
      defaultStagione,
      discipline,
      tesseramento_ranking_categoria_classifica,
      rankingGruppi,
      selectedCategoria,
      selectedGruppo,
      coefficiente,
      resetRankingGruppi,
      setRankingCategoria,
      setRankingGruppo,
      selectedDisciplina,
      setRankingDisciplina,
      nome_persona,
      id_persona,
      getTesserati,
      resetTesseratiList,
      spliceTesserati,
      tesseratiList: computed(() => store.getters.personeSocieta),
      schema,
      onSubmit,
      onInvalidSubmit,
      selectedStagione,
      setRankingStagione,
      resetField,
      alertCampiObb,
      data_validita,
      format,
      jsonDetailRanking,
      loadRanking,
      caricato,
      disableButton,
      isLoading,
    };
  },
});
</script>

<style scoped>
.modal-content {
  width: 140%;
}

.modal-body {
  padding: 0px;
}

@media (max-width: 991.98px) {
  .modal-dialog {
    max-width: 52%;
    margin: 9rem;
    margin-top: 5rem;
  }
}

@media (max-width: 575.98px) {
  .modal-dialog {
    max-width: 70%;
    margin: 3px;
    margin-top: 4rem;
  }
}

.image-input [data-kt-image-input-action] {
  cursor: pointer;
  position: absolute;
  transform: none;
}

.btn.btn-icon:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush) {
  border: solid 1px;
  border-color: #d6d8dd;
}

.btn.btn-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-right: 3px;
  height: 28px;
  width: 28px;
}

.image-input [data-kt-image-input-action="change"] {
  left: 0%;
  top: 0%;
}
</style>

<template>
  <div>
    <div class="card shadow">
      <form autocomplete="off">
        <div class="pt-4 pb-4 pe-4 ps-4" @keyup.enter="$emit('getRankingList')">
          <div class="row pb-4">
            <div class="col-sm-4 filter">
              <SelectInput
                :options="discipline"
                name="discipline"
                placeholder="Disciplina"
                :value="selectedDisciplina"
                @changeSelect="setRankingDisciplina($event)"
              />
            </div>
            <div
              class="col-sm-4 filter"
              v-if="tesseramento_ranking_categoria_classifica"
            >
              <select
                class="form-select"
                aria-label=""
                placeholder="Categoria"
                :value="selectedCategoria ? selectedCategoria : 0"
                @change="setRankingCategoria($event.target.value)"
              >
                <template v-if="selectedDisciplina == 172">
                  <option :value="0" selected>Categoria</option>
                  <option
                    v-for="rank in tesseramento_ranking_categoria_classifica"
                    :key="rank.id"
                    :value="rank.id"
                  >
                    {{ rank.label }}
                  </option>
                </template>
                <template v-else>
                  <option :value="0" selected>Categoria</option>
                  <option
                    v-for="rank in tesseramento_ranking_categoria_classifica.slice(
                      0,
                      4
                    )"
                    :key="rank.id"
                    :value="rank.id"
                  >
                    {{ rank.label }}
                  </option>
                </template>
              </select>
              <!-- <SelectInput
                :options="tesseramento_ranking_categoria_classifica"
                name="tesseramento_ranking_categoria_classifica"
                placeholder="Categoria"
                :value="selectedCategoria"
                @changeSelect="setRankingCategoria($event)"
              /> -->
            </div>
            <div class="col-sm-4 filter">
              <SelectInput
                :options="
                  rankingGruppi ? rankingGruppi.tesseramento_ranking_gruppi : []
                "
                name="rankingGruppi"
                placeholder="Gruppo"
                :value="selectedGruppo"
                @changeSelect="setRankingGruppo($event)"
                :disabled="!selectedCategoria || selectedCategoria >= 5"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6 filter">
              <div class="dp__input_wrap text-gray-600">
                <input
                  placeholder="Tesserato"
                  name="nome_tesserato"
                  type="text"
                  class="form-control"
                  @input="
                    inputFocus = 'persona';
                    getPersone($event.target.value);
                  "
                  :value="nomeTesseratoRanking"
                  autocomplete="off"
                  @click="inputFocusTesserato = true"
                />
                <svg
                  @click="
                    setTesseratoRanking({ name: null, value: null });
                    inputFocusTesserato = false;
                  "
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  class="dp__icon dp__clear_icon dp__input_icons"
                >
                  <path
                    d="M23.057 7.057l-16 16c-0.52 0.52-0.52 1.365 0 1.885s1.365 0.52 1.885 0l16-16c0.52-0.52 0.52-1.365 0-1.885s-1.365-0.52-1.885 0z"
                  ></path>
                  <path
                    d="M7.057 8.943l16 16c0.52 0.52 1.365 0.52 1.885 0s0.52-1.365 0-1.885l-16-16c-0.52-0.52-1.365-0.52-1.885 0s-0.52 1.365 0 1.885z"
                  ></path>
                </svg>
              </div>
              <ListTesserati
                v-if="inputFocus === 'persona'"
                :list="tesserati"
                @hideList="
                  inputFocus = null;
                  resetTesserati;
                "
                @selectedTesserato="
                  resetTesserati();
                  setTesseratoRanking($event);
                  inputFocus = null;
                "
              ></ListTesserati>
            </div>
            <div class="col-sm-3 filter">
              <SelectInput
                :options="sessoLookup"
                name="sessoLookup"
                placeholder="Sesso"
                :value="selectedSesso"
                @changeSelect="setSessoRanking($event)"
              />
            </div>
          </div>
          <div class="text-center mt-5">
            <button
              type="button"
              data-bs-toggle=""
              data-bs-target=""
              aria-expanded="false"
              aria-controls=""
              class="badge rounded-pill bg-light text-gray-600 ms-4"
              @click="$emit('getRankingList')"
              :disabled="!loaded"
            >
              Cerca <i class="bi bi-search fs-6 text-gray-600"> </i>
            </button>
            <span
              type="button"
              data-bs-toggle=""
              data-bs-target=""
              aria-expanded="false"
              aria-controls=""
              class="badge rounded-pill bg-light text-gray-600 ms-4"
              @click="
                resetRankingGruppi();
                $emit('resetFilters');
              "
            >
              <i class="bi bi-arrow-clockwise fs-6 text-gray-600"> </i
            ></span>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
/* import "vue3-date-time-picker/dist/main.css";
import { defineComponent } from "vue";

export default defineComponent({
  components: {},
}); */

import { defineComponent, computed, ref, watch, getCurrentInstance } from "vue";
import { useStore } from "vuex";
import ListTesserati from "@/components/components-fit/utility/ListTesserati.vue";
import SelectInput from "@/components/components-fit/utility/inputs/SelectInput.vue";

export default defineComponent({
  name: "filtriRankinng",
  emits: ["resetFilters", "getRankingList"],
  props: {
    campiLocked: {
      type: String,
      required: true,
    },
  },
  components: {
    ListTesserati,
    SelectInput,
  },
  setup(props, { emit }) {
    const inputFocusTesserato = ref(false);
    const store = useStore();

    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;
    const discipline = computed(() =>
      store.getters.getStateFromName("discipline")
    );
    const tesseramento_ranking_categoria_classifica = computed(() =>
      store.getters.getStateFromName(
        "tesseramento_ranking_categoria_classifica"
      )
    );

    const keys = ref("d|trcc");
    if (!discipline.value || !tesseramento_ranking_categoria_classifica.value) {
      store.dispatch("setStoreData", {
        keys: { keys: keys.value },
        apiLink: globalApi.COMBOLIST_GET,
      });
    }

    const getPersone = (string) => {
      store.commit("setNomeTesseratoRanking", string);
      if (string === "") {
        store.dispatch("svuotaPersoneNomeCognome");
        return;
      } else if (string.length < 3) return;
      store.dispatch("getPersoneNomeCognome", string);
    };
    const resetTesserati = () => {
      store.dispatch("svuotaPersoneNomeCognome");
    };

    const resetRankingGruppi = () => {
      store.commit("resetObjectFromName", "ranking_gruppi");
    };

    const setRankingDisciplina = (id) => {
      store.commit("setDisciplinaRanking", id);
      resetRankingGruppi();
      store.commit("setCategoriaRanking", null);
    };

    /*   const setRankingDisciplina = (event) => {
      selectedGruppo.value = null;
      resetRankingGruppi();
    }; */

    const selectedCategoria = computed(() => store.getters.categoriaRanking);
    const selectedDisciplina = computed(() => store.getters.disciplinaRanking);
    const selectedGruppo = computed(() => store.getters.gruppoRanking);

    const setRankingCategoria = (event) => {
      store.commit("setCategoriaRanking", event);
      // pulisce i gruppi
      resetRankingGruppi();
      store.commit("setGruppoRanking", null);
      // i nuovi gruppi

      store.dispatch("setStoreData", {
        keys: {
          id_disciplina: selectedDisciplina.value,
          id_categoria_classifica: selectedCategoria.value,
        },
        apiLink: globalApi.LOOKUP_TESSERAMENTO_RANKING_GRUPPI_GET,
        itemName: "ranking_gruppi",
      });
    };

    const setRankingGruppo = (event) => {
      store.commit("setGruppoRanking", event);
    };

    const setTesseratoRanking = (id) => {
      store.commit("setTesseratoRanking", id);
    };

    const setSessoRanking = (id) => {
      store.commit("setSessoRanking", id);
    };

    const stagione = computed(() => store.getters.stagioneSelected);
    watch(stagione, () => {
      emit("getRankingList");
    });

    const inputFocus = ref(null);

    return {
      inputFocus,
      getPersone,
      resetTesserati,
      tesserati: computed(() => store.getters.personeSocieta),
      discipline,
      tesseramento_ranking_categoria_classifica,
      setTesseratoRanking,
      nomeTesseratoRanking: computed(() => store.getters.nomeTesseratoRanking),
      selectedDisciplina,
      selectedCategoria,
      selectedSesso: computed(() => store.getters.sessoRanking),
      selectedGruppo,
      stagione: computed(() => store.getters.stagioneSelected),
      sessoLookup: [
        { id: "M", label: "Maschile" },
        { id: "F", label: "Femminile" },
      ],
      setSessoRanking,
      setRankingCategoria,
      rankingGruppi: computed(() =>
        store.getters.getStateFromName("ranking_gruppi")
      ),
      setRankingGruppo,
      setRankingDisciplina,
      resetRankingGruppi,
      inputFocusTesserato,
      loaded: computed(() =>
        store.getters.getStateFromName("loadedranking_list")
      ),
    };
  },
});
</script>

<style></style>

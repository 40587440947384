<template>
  <div>
    <div class="row mt-5 justify-content-between">
      <div class="col-3" v-if="!readOnly">
        <div>
          <button
            type="button"
            class="btn btn-light text-gray-700 fs-6 text-start"
            data-bs-toggle="modal"
            data-bs-target="#kt_modal_add_ranking"
          >
            <i class="bi bi-plus fs-6 text-gray-700"></i>nuova classifica
          </button>
          <AddRanking @refreshRankingList="refreshRankingList" />
        </div>
      </div>
      <div class="col-3 text-center">
        <button
          type="button"
          class="btn btn-light text-gray-700 fs-6"
          @click="esportaLista()"
        >
          <i class="bi bi-download fs-6 text-gray-700 me-1"></i>esporta lista
          filtrata
        </button>
      </div>
      <div class="col-3 text-center">
        <div class="btn btn-light text-gray-700 fs-6">
          <input
            class="form-check-input me-2"
            type="checkbox"
            @click="setTutteLeStagioni"
            :checked="tutteStagioniRanking"
          />visualizza tutte le stagioni
        </div>
      </div>
      <div class="col-3">
        <div class="text-end mt-4" style="margin-right: 19px">
          Visualizzati
          <b v-if="record > 0">
            {{ rowsToSkip + fetchRows - (fetchRows - 1) }}
            -
            {{
              rowsToSkip + fetchRows > record ? record : rowsToSkip + fetchRows
            }}
          </b>
          <b v-else> 0 </b>
          elementi di <b>{{ record }}</b>
        </div>
      </div>
    </div>
    <br />
  </div>
  <div class="mx-auto my-auto text-center" v-if="!loaded">
    <div class="spinner-border text-gray-600" role="status"></div>
    <span class="text-gray-600 ps-3 fs-2">Loading</span>
  </div>
  <div
    v-else-if="
      ranking_list.length === 0 && loaded && (status == 200 || status == 204)
    "
    class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
  >
    <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
    <span class="text-gray-700 ps-3 fs-2"> Attenzione! Nessun risultato. </span>
    <span
      type="button"
      data-bs-toggle=""
      data-bs-target=""
      aria-expanded="false"
      aria-controls=""
      class="text-gray-600 pt-4"
      @click.prevent="$emit('resetFilters')"
    >
      <i class="bi bi-arrow-clockwise fs-2 text-gray-800"> </i
    ></span>
  </div>
  <div
    v-else-if="status != 200 && status != 204"
    class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
  >
    <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
    <span class="text-gray-700 ps-3 fs-2">
      Attenzione! Si è verificato un errore. Riprovare più tardi.
    </span>
    <span
      type="button"
      data-bs-toggle=""
      data-bs-target=""
      aria-expanded="false"
      aria-controls=""
      class="text-gray-600 pt-4"
    >
      <router-link v-if="isEnabled('fnLinkBachecaCompleta')" to="/bacheca">
        <i class="bi bi-house fs-2 text-gray-800"> </i
      ></router-link>
      <router-link v-else to="/bacheca-public">
        <i class="bi bi-house fs-2 text-gray-800"> </i></router-link
    ></span>
  </div>
  <div v-else>
    <Datatable
      :table-header="tableHeader"
      :table-data="ranking_list"
      :total="record"
      :current-page="currentPage"
      :rows-per-page="fetchRows"
      :sortLabel="sortColumn"
      :order="sortOrder"
      @sort="setSortOrderColumn"
      @items-per-page-change="setFetchRowsRanking"
      @current-change="setCurrentPageRanking"
    >
      <template v-slot:cell-stagione="{ row: rankingList }"
        >{{ rankingList.stagione }}
      </template>
      <template v-slot:cell-nominativo_completo="{ row: rankingList }"
        ><div class="tab-long">
          {{ rankingList.nominativo_completo }}
        </div>
      </template>
      <template v-slot:cell-disciplina_view="{ row: rankingList }"
        >{{ rankingList.disciplina_view }}
      </template>
      <template v-slot:cell-categoria="{ row: rankingList }"
        >{{ rankingList.categoria }}
      </template>
      <template v-slot:cell-gruppo="{ row: rankingList }"
        >{{ rankingList.gruppo }}
      </template>
      <template v-slot:cell-data_inserimento="{ row: rankingList }"
        ><div class="text-center">
          {{ getDateFromUTC(new Date(rankingList.data_inserimento)) }}
        </div>
      </template>
      <template v-slot:cell-options="{ row: rankingList }"
        >{{ rankingList.options }}
        <div class="btn-group">
          <button
            v-if="!readOnly"
            class="btn btn-sm dropdown p-1"
            data-bs-toggle="modal"
            data-bs-target="#kt_modal_edit_ranking"
            @click.prevent="viewEditRanking(rankingList.id)"
          >
            <i class="bi bi-pen text-dark fs-4"></i>
          </button>
          <button
            class="btn btn-sm dropdown p-1"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="bi bi-three-dots-vertical text-dark fs-4"></i>
          </button>
          <ul class="dropdown-menu">
            <router-link
              :to="`/persone/dettaglio-persona/classifica-persona/${rankingList.id_persona}`"
            >
              <li role="presentation" class="navi-item">
                <div class="b-dropdown-text text-start py-2 px-4">
                  <div class="navi-link">
                    <i class="bi bi-eye text-dark fs-5"></i>
                    <span class="text-dark ps-1 fs-7">
                      Storico classifica
                    </span>
                  </div>
                </div>
              </li></router-link
            >
            <a
              href="#"
              @click.prevent="$emit('removeRanking', rankingList.id)"
              v-if="!readOnly"
            >
              <li role="presentation" class="navi-item">
                <div class="b-dropdown-text text-start py-2 px-4">
                  <div class="navi-link">
                    <i class="bi bi-trash text-dark fs-5"></i>
                    <span class="text-dark ps-1 fs-7"> Elimina </span>
                  </div>
                </div>
              </li></a
            >
          </ul>
        </div>
      </template>
    </Datatable>
    <EditRanking
      @refreshRankingList="refreshRankingList"
      :isLoaded="isLoaded"
      :id_ranking="id_ranking"
    />
  </div>
</template>

<script>
import { useStore } from "vuex";
import { ref, computed } from "vue";
import Datatable from "../../kt-datatable/KTDatatable.vue";
import AddRanking from "./AddRanking.vue";
import EditRanking from "./EditRanking.vue";
import isEnabled from "@/composables/isEnabled.js";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default {
  name: "table-ranking",
  components: {
    Datatable,
    AddRanking,
    EditRanking,
  },
  emits: ["removeRanking", "getRankingList", "resetFilters"],
  props: {},
  setup(props, { emit }) {
    const store = useStore();

    const getDateFromUTC = (date) => {
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      return `${day}/${month}/${year}`;
    };

    const isLoaded = ref(false);
    const id_ranking = ref(null);

    const viewEditRanking = (id) => {
      isLoaded.value = false;
      id_ranking.value = id;
    };

    const tableHeader = ref([
      {
        name: "Stagione",
        key: "stagione",
        sortable: false,
      },
      {
        name: "Tesserato",
        key: "nominativo_completo",
      },
      {
        name: "Disciplina",
        key: "disciplina_view",
      },
      {
        name: "Categoria",
        key: "categoria",
        sortable: false,
      },
      {
        name: "Gruppo/Coeff",
        key: "gruppo",
      },
      {
        name: "Data decorrenza",
        key: "data_inserimento",
        align: "center",
      },
      {
        key: "options",
        sortable: false,
      },
    ]);

    const setFetchRowsRanking = (e) => {
      store.commit("setFetchRowsRanking", e);
      emit("getRankingList");
    };
    const setCurrentPageRanking = (page) => {
      store.commit("setCurrentPageRanking", page);
      emit("getRankingList");
    };
    const setSortOrderColumn = ({ columnName, order }) => {
      store.commit("setSortColumnRanking", columnName);
      store.commit("setSortOrderRanking", order);
      emit("getRankingList");
    };

    const refreshRankingList = () => {
      emit("getRankingList");
    };

    const esportaLista = () => {
      Swal.fire({
        html: "Funzionalità presto disponibile",
        icon: "warning",
        buttonsStyling: false,
        confirmButtonText: "Chiudi",
        customClass: {
          confirmButton: "btn fw-bold btn-light-warning",
        },
      });
    };

    const setTutteLeStagioni = () => {
      store.commit("setTutteLeStagioniRanking");
      emit("getRankingList");
    };

    return {
      getDateFromUTC,
      ranking_list: computed(() =>
        store.getters.getStateFromName("resultsranking_list")
      ),
      loaded: computed(() =>
        store.getters.getStateFromName("loadedranking_list")
      ),
      record: computed(() =>
        store.getters.getStateFromName("recordranking_list")
      ),
      status: computed(() =>
        store.getters.getStateFromName("statusranking_list")
      ),
      readOnly: computed(() => store.getters.readOnlySelectedRoute),
      tutteStagioniRanking: computed(() => store.getters.tutteStagioniRanking),
      setTutteLeStagioni,
      currentPage: computed(() => store.getters.currentPageRanking),
      rowsToSkip: computed(() => store.getters.rowsToSkipRanking),
      fetchRows: computed(() => store.getters.fetchRowsRanking),
      sortColumn: computed(() => store.getters.sortColumnRanking),
      sortOrder: computed(() => store.getters.sortOrderRanking),
      setFetchRowsRanking,
      setCurrentPageRanking,
      setSortOrderColumn,
      tableHeader,
      viewEditRanking,
      isLoaded,
      refreshRankingList,
      id_ranking,
      isEnabled,
      esportaLista,
    };
  },
};
</script>

<style scoped></style>

<template>
  <div>
    <loading
      :active="isLoading"
      :is-full-page="fullPage"
      :z-index="1100"
    ></loading>
    <FilterRanking
      @getRankingList="searchRankingList"
      @resetFilters="resetFilters"
    />
    <TableRanking
      @removeRanking="removeRanking"
      @getRankingList="getRankingList"
      @resetFilters="resetFilters"
    />
  </div>
</template>

<script>
import {
  defineComponent,
  onMounted,
  ref,
  computed,
  getCurrentInstance,
} from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import FilterRanking from "../../components/components-fit/ranking/FilterRanking.vue";
import TableRanking from "../../components/components-fit/ranking/TableRanking.vue";
import { useStore } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { deleteRanking } from "../../requests/ranking";

import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";

export default defineComponent({
  name: "ranking",
  components: { FilterRanking, TableRanking, Loading },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Ranking", []);
    });
    const store = useStore();

    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;
    const stagione = computed(() => store.getters.stagioneSelected);
    const currentPage = computed(() => store.getters.currentPageRanking);
    const rowsToSkip = computed(() => store.getters.rowsToSkipRanking);
    const fetchRows = computed(() => store.getters.fetchRowsRanking);
    const sortColumn = computed(() => store.getters.sortColumnRanking);
    const sortOrder = computed(() => store.getters.sortOrderRanking);
    const id_persona = computed(() => store.getters.tesseratoRanking);
    const sesso = computed(() => store.getters.sessoRanking);
    const disciplina = computed(() => store.getters.disciplinaRanking);
    const categoria = computed(() => store.getters.categoriaRanking);
    const id_gruppo = computed(() => store.getters.gruppoRanking);
    const tutteStagioniRanking = computed(
      () => store.getters.tutteStagioniRanking
    );

    const getRankingList = () => {
      // attivato tramite evento @getRanking
      // prendo lista da store dinamico. Passo keys (i parametri in input)
      // apiLink(link all'api), itemName(nome dell'array)
      store.dispatch("setStoreListData", {
        keys: {
          id_persona: id_persona.value,
          sesso: sesso.value,
          disciplina: disciplina.value,
          categoria: categoria.value,
          id_gruppo: id_gruppo.value,
          stagione: tutteStagioniRanking.value ? 10000 : stagione.value,
          rowsToSkip: rowsToSkip.value,
          fetchRows: fetchRows.value,
          sortColumn: sortColumn.value,
          sortOrder: sortOrder.value,
        },
        apiLink: globalApi.RANKING_LIST,
        itemName: "ranking_list",
      });
    };
    // reset filtri. viene attivato tramite evento @resetFilters
    const resetFilters = () => {
      store.commit("resetFiltersRanking");
      store.commit("resetObjectFromName", "ranking_gruppi");
      getRankingList();
    };

    const loaded = computed(() =>
      store.getters.getStateFromName("loadedranking_list")
    );

    const searchRankingList = () => {
      if (!loaded.value) return;
      store.commit("setRowsToSkipRanking");
      getRankingList();
    };

    getRankingList();

    const isLoading = ref(false);

    const removeRanking = (id) => {
      Swal.fire({
        title:
          "Sei proprio sicuro di voler eliminare la classifica selezionata?",
        showCancelButton: true,
        confirmButtonText: "Conferma",
        cancelButtonText: "Annulla",
      }).then((result) => {
        if (result.isConfirmed) {
          isLoading.value = true;
          const responseDelete = deleteRanking(id);

          responseDelete.then((value) => {
            if (value.status == 200) {
              Swal.fire({
                html: "Ranking eliminato",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "OK",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-success",
                },
              });
              getRankingList();
            } else {
              const strMsg = ref(
                value.data.message
                  ? value.data.message
                  : "Attenzione! Si è verificato un errore. Riprovare più tardi"
              );
              Swal.fire({
                html: strMsg.value,
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "OK",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-danger",
                },
              });
            }
            isLoading.value = false;
          });
        }
      });
    };

    return {
      getRankingList,
      resetFilters,
      removeRanking,
      currentPage,
      searchRankingList,
      isLoading,
    };
  },
});
</script>
